@import url("./components/styles/Header.css");
@import url("./components/styles/Hero.css");
@import url("./components/styles/StatsCard.css");
@import url("./components/styles/Features.css");
@import url("./components/styles/Pricing.css");
@import url("./pages/styles/Signup.css");
@import url("./pages/styles/Checkout.css");
@import url("./pages/styles/success.css");
@import url("./pages/styles/contact.css");


body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
