/* Stats Section Styling */
.stats-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #f8f9fa;
}

.stats-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.stats-card {
  width: 220px;
  background: #fff;
  padding: 2rem;
  margin: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.stats-card h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.stats-card p {
  font-size: 2rem;
  font-weight: bold;
  color: #0072ff;
}

.stats-card:hover {
  transform: translateY(-5px);
}

@media (min-width: 768px) {
  .stats-card {
    width: 250px;
  }
}
