/* Updated FAQ Section with Box Shadows, Right-Aligned Plus Icon */
.faq-section {
  padding: 20px;
  max-width: 900px;
  margin: auto;
  margin-bottom: 20px;
  background-color: #fff;
  color: #333;
  border-radius: 8px;
  overflow: hidden;
}

.faq-section h2 {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 30px;
  color: #2c3e50;
  font-family: 'Arial', sans-serif;
  font-weight: 600;
}

.faq-section h2 span {
  color: #007bff;
}

.faq-list {
  display: flex;
  flex-direction: column;
}

.faq-item {
  margin-bottom: 15px;
  background-color: #f9f9f9; /* Background color for the FAQ box */
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); /* Box shadow for a card-like effect */
  overflow: hidden;
  transition: transform 0.2s ease, box-shadow 0.3s ease;
}

.faq-item:hover {
  transform: translateY(-5px); /* Hover effect to lift the box */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15); /* Darker shadow on hover */
}

.faq-question {
  padding: 15px;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  background-color: #fff;
  border-bottom: 1px solid #ddd; /* Border between question and answer */
  cursor: pointer;
  display: flex;
  justify-content: space-between; /* Move plus icon to the right */
  align-items: center;
  border-radius: 8px;
  outline: none;
}

.faq-question:hover {
  background-color: #f0f0f0; /* Slight change on hover */
}

.faq-question span {
  font-size: 1rem;
}

.plus-icon {
  font-size: 1.5rem;
  color: #3498db;
}

.faq-answer {
  padding: 15px;
  background-color: #fff;
  color: #7f8c8d;
  font-size: 0.95rem;
  line-height: 1.6;
  border-top: 1px solid #ddd; /* Border to separate answer */
  opacity: 0; /* Hidden initially */
  visibility: hidden; /* Hidden initially */
  transition: opacity 0.3s ease, visibility 0.3s ease; /* Smooth transition */
}

.faq-answer.visible {
  opacity: 1; /* Show answer when visible */
  visibility: visible;
}

.faq-item:focus-within {
  outline: none;
}

/* Mobile-Friendly Styles */
@media (max-width: 768px) {
  .faq-section {
    padding: 15px;
  }

  .faq-section h2 {
    font-size: 1.6rem;
  }

  .faq-question {
    font-size: 0.95rem;
    padding: 12px;
  }

  .faq-answer {
    font-size: 0.9rem;
    padding: 12px;
  }

  .faq-item {
    margin-bottom: 12px;
  }
}

@media (max-width: 480px) {
  .faq-section {
    padding: 10px;
  }

  .faq-section h2 {
    font-size: 1.4rem;
  }

  .faq-question {
    font-size: 0.9rem;
  }

  .faq-answer {
    font-size: 0.85rem;
    padding: 10px;
  }
}
