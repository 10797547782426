/* Base styles for full centering and custom theme */
.error-message {
  background-color: #ffffff; /* Blue background */
  color: #ba3a3a; /* White text */
  padding: 0.5rem 2rem;
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%); /* Full center */
  z-index: 100000;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  max-width: 90%;
  min-width: 250px;
  font-family: 'Roboto', sans-serif; /* Change to custom font */
}

.error-message p {
  flex: 1;
  margin-right: 1rem;
  font-size: 1rem;
  font-weight: 500; /* Lighter weight for better readability */
}

.close-btn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 1.5rem;
  color: #fff; /* White color for close icon */
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-btn svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}

/* Tablet and larger screens */
@media (min-width: 768px) {
  .error-message {
    max-width: 400px;
    font-size: 1.3rem;
  }

  .error-message p {
    font-size: 1.1rem;
  }

  .close-btn svg {
    width: 24px;
    height: 24px;
  }
}

/* Desktop */
@media (min-width: 1024px) {
  .error-message {
    max-width: 500px;
  }
}
