
/* Styles for the PasswordProtectedPage */
.PasswordProtectedPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  /* background: linear-gradient(135deg, #ff7e5f, #feb47b); */
  position: relative;
}

.PasswordProtectedPage:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://www.transparenttextures.com/patterns/asfalt.png');
  opacity: 0.1;
  background-size: 100px 100px;
}

.PasswordProtectedPage p {
  font-size: 1.1rem;
  color: white;
  font-weight: 500;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

/* Styles for the AdminDashboard */
.AdminDashboard {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  transition: transform 0.3s ease;
}

.AdminDashboard:hover {
  transform: translateY(-10px);
}

.AdminDashboard h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #4a4a4a;
  text-align: center;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.AdminDashboard form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.AdminDashboard label {
  font-size: 1rem;
  font-weight: bold;
  color: #495057;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.AdminDashboard input,
.AdminDashboard textarea,
.AdminDashboard button {
  font-size: 1rem;
  padding: 12px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.AdminDashboard input,
.AdminDashboard textarea {
  border: 1px solid #ced4da;
}

.AdminDashboard input:focus,
.AdminDashboard textarea:focus {
  border: 1px solid #007bff;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.5);
}

.AdminDashboard textarea {
  resize: none;
  max-width: 100%;
}

.AdminDashboard button {
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.AdminDashboard button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.AdminDashboard button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
  transform: none;
}

/* Styles for the response section */
.AdminDashboard .response {
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  font-size: 1rem;
}

.AdminDashboard .response h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #343a40;
  font-weight: bold;
}

.AdminDashboard .response p {
  font-size: 1rem;
  margin: 5px 0;
}

.AdminDashboard .response strong {
  color: #495057;
}

.AdminDashboard .response.success {
  background-color: #eafaf1;
  border: 1px solid #28a745;
  color: #28a745;
}

.AdminDashboard .response.failure {
  background-color: #f8d7da;
  border: 1px solid #dc3545;
  color: #dc3545;
}

/* Media Queries for Larger Screens */
@media (min-width: 600px) {
  .AdminDashboard {
    width: 500px;
  }

  .AdminDashboard h1 {
    font-size: 2.2rem;
  }

  .AdminDashboard label {
    font-size: 1.1rem;
  }

  .AdminDashboard input,
  .AdminDashboard textarea,
  .AdminDashboard button {
    font-size: 1.1rem;
  }

  .AdminDashboard .response h2 {
    font-size: 1.3rem;
  }
}

@media (min-width: 900px) {
  .AdminDashboard {
    width: 600px;
  }

  .AdminDashboard h1 {
    font-size: 2.4rem;
  }

  .AdminDashboard input,
  .AdminDashboard textarea,
  .AdminDashboard button {
    font-size: 1.2rem;
  }

  .AdminDashboard .response h2 {
    font-size: 1.5rem;
  }
}
