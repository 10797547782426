/* Hero Section Styling */
.hero-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 2rem;
}

.hero-section h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.hero-section .hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Background Circle Animation */
.background-circle {
  animation: pulse 6s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* Responsive Design */
@media (min-width: 992px) {
  .hero-section {
    flex-direction: row;
    text-align: left;
    padding: 4rem;
  }

  .hero-section h1 {
    font-size: 3rem;
  }
  
  .hero-section .hero-content {
    text-align: left;
  }
}
