:root {
  --primary-color: #1a73e8;
  --secondary-color: #555555;
  --accent-color: #f1f3f4;
  --error-color: #d93025;
  --success-color: #34a853;
  --font-family: "Poppins", sans-serif;
  --background-color: #f9f9f9;
  --card-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px, rgba(0, 0, 0, 0.05) 0px 1px 3px;
}


.checkout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  background: linear-gradient(135deg, #eef2f3, #8e9eab);
}

.checkout-form {
  background: #ffffff;
  max-width: 500px;
  width: 100%;
  padding: 30px;
  border-radius: 16px;
  box-shadow: var(--card-shadow);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.checkout-form:hover {
  transform: scale(1.02);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 10px, rgba(0, 0, 0, 0.1) 0px 4px 5px;
}

.checkout-title {
  font-size: 2rem;
  color: var(--primary-color);
  font-weight: 700;
  margin-bottom: 15px;
  text-align: center;
}

.checkout-description {
  font-size: 1.1rem;
  color: var(--secondary-color);
  text-align: center;
  margin-bottom: 20px;
  line-height: 1.6;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-size: 0.95rem;
  font-weight: 600;
  color: var(--secondary-color);
  margin-bottom: 5px;
  display: block;
}

.form-group input {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  background: var(--accent-color);
  border: none;
  border-radius: 8px;
  outline: none;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.form-group input:focus {
  background: white;
  box-shadow: 0px 0px 3px 1px var(--primary-color);
}

.card-input {
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: var(--accent-color);
}

.checkout-button {
  width: 100%;
  background: var(--primary-color);
  color: white;
  font-size: 1.2rem;
  padding: 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  font-weight: 600;
}

.checkout-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

.checkout-button:hover:not(:disabled) {
  background: #165abc;
  transform: translateY(-2px);
}

.error-message {
  color: var(--error-color);
  background: #fdd8d5;
  padding: 10px;
  border-radius: 8px;
  margin-top: 10px;
  text-align: center;
  font-size: 0.95rem;
}

.success-message {
  color: var(--success-color);
  background: #d4edda;
  padding: 10px;
  border-radius: 8px;
  margin-top: 10px;
  text-align: center;
  font-size: 0.95rem;
}

@media (max-width: 768px) {
  .checkout-form {
    padding: 20px;
  }

  .checkout-title {
    font-size: 1.8rem;
  }

  .checkout-description {
    font-size: 1rem;
  }
}


.encryption-info {
  text-align: center;
  margin-top: 20px;
}

.encryption-note {
  font-size: 0.85rem;
  color: var(--secondary-color);
  margin-top: 5px;
  line-height: 1.5;
}
