/* Global reset and base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Main container */
.page-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  /* align-items: center; */
  padding: 50px 20px 200px 20px;
  width: 100%;
  /* height: 95vh; */
}

/* Typography Section (Left Side in Desktop) */
.typography-section {
  text-align: center;
  max-width: 900px;
  width: 100%;
  position: relative;
  padding: 20px;
}

.typography-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 15px;
  line-height: 1.4;
}

.typography-description {
  font-size: 1.3rem;
  color: #555;
  margin-bottom: 30px;
  line-height: 1.6;
}

.typography-list {
  list-style: none;
  padding: 0;
  font-size: 1.2rem;
  color: #333;
}

.typography-list-item {
  margin: 12px 0;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.typography-list-item::before {
  content: "✔";
  margin-right: 10px;
  color: #4caf50;
  font-size: 1.5rem;
}

/* Form Section (Right Side in Desktop) */
.form-section {
  max-width: 480px;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
  display: inline-block;
}

.input-field,
.select-field,
.textarea-field {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 1rem;
  width: 100%;
  margin-top: 5px;
  transition: all 0.3s ease;
}

.input-field:focus,
.select-field:focus,
.textarea-field:focus {
  border-color: #4caf50;
  outline: none;
}
.uppercase {
  text-transform: uppercase;
}

.textarea-field {
  resize: none;
}

.submit-button {
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1.2rem;
  width: 100%;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #45a049;
}

.error-message-google-review {
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
}
.info-message-google-review {
  color: gray;
  font-size: 0.9rem;
  margin-top: 5px;
}

/* Responsive Design */
@media (min-width: 768px) {
  .page-container {
    flex-direction: row;
    justify-content: center;
  }

  .typography-section {
    text-align: left;
    padding-left: 40px;
  }

  .form-section {
    max-width: 420px;
  }
}
