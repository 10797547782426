/* Section styling */
#pricing {
  background: linear-gradient(to bottom, #f9fafa, #ffffff);
  padding: 5rem 0;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.section-title span {
  color: #007bff;
}

.section-subtitle {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 2rem;
}

/* Card styling */
.pricing-card {
  border: none;
  border-radius: 1rem;
  overflow: hidden;
  background: #ffffff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

/* Header styling with gradients */
.pricing-header {
  padding: 1rem;
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.bg-gradient-basic {
  background: linear-gradient(135deg, #007bff, #6ec1e4);
}

.bg-gradient-standard {
  background: linear-gradient(135deg, #6c757d, #8a8d91);
}

.bg-gradient-premium {
  background: linear-gradient(135deg, #ffc107, #ffdd80);
}

/* Body styling */
.pricing-body {
  padding: 2rem 1.5rem;
  text-align: center;
}

.pricing-amount {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.pricing-features {
  list-style: none;
  padding: 0;
  margin: 0 0 1.5rem;
  color: #666;
}

.pricing-features li {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

/* Button styling */
.btn-gradient {
  background: linear-gradient(to right, #007bff, #0056b3);
  color: #fff;
  border: none;
  border-radius: 2rem;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  transition: background 0.3s ease;
  text-transform: uppercase;
}

.btn-gradient:hover {
  background: linear-gradient(to right, #0056b3, #003e80);
  color: #fff;
}
