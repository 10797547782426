#features {
  .text-primary {
    font-family: 'Helvetica', sans-serif;
    font-weight: bold;
    color: #0056b3;
  }

  .feature-item {
    margin-bottom: 20px;

    .icon-wrapper {
      width: 80px;
      height: 80px;
      margin: 0 auto 20px;
      background-color: #f0f9ff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #e3f4ff;
        transform: scale(1.1);
      }
    }

    .feature-icon {
      width: 40px;
      height: 40px;
      fill: #007bff;
    }

    h4 {
      font-size: 1.25rem;
      font-weight: 600;
    }

    p {
      font-size: 1rem;
      color: #555;
    }
  }
}
