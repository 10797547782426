/* General Header Styling */
.header {
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1030;
  padding: 0.5rem 1rem;
  align-items: center;
  justify-content: space-between; /* Ensure logo and menu stay in one row */
}

/* Logo Styling */
.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2575fc;
  margin: 0;
}

/* Menu Toggle Button */
.menu-toggle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
}

.menu-toggle .line {
  background: #333;
  height: 3px;
  width: 100%;
  border-radius: 2px;
  transition: transform 0.3s, background 0.3s;
}

.menu-toggle.active .line:nth-child(1) {
  transform: translateY(7px) rotate(45deg);
}
.menu-toggle.active .line:nth-child(2) {
  opacity: 0;
}
.menu-toggle.active .line:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
}

/* Mobile Menu */
.mobile-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #f8f9fa;
  display: none;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: slideDown 0.3s ease-out;
}

.mobile-menu.open {
  display: flex;
}

.menu-link {
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
  text-decoration: none;
  margin: 0.5rem 0;
  transition: color 0.3s ease;
}

.menu-link:hover {
  color: #2575fc;
}

/* Get Started Button */
.get-started {
  background: linear-gradient(135deg, #2575fc, #6a8fff); /* Gradient background */
  color: #fff;
  font-size: 1.0rem; /* Slightly larger font size */
  font-weight: 800;
  padding: 0.6rem 1.8rem; /* Adjusted padding for better size */
  border-radius: 50px; /* More rounded corners */
  border: 2px solid #2575fc; /* Border color to match the background */
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 85, 179, 0.4); /* Subtle shadow for depth */
  transition: all 0.3s ease;
  text-transform: uppercase; /* Uppercase text for a bolder look */
}

.get-started:hover {
  background: linear-gradient(135deg, #0056b3, #4c8bf5); /* Hover gradient for smooth effect */
  transform: translateY(-4px); /* Slight movement effect */
  box-shadow: 0 8px 20px rgba(0, 85, 179, 0.5); /* Stronger shadow on hover */
}

.get-started:focus {
  outline: none; /* Remove default outline */
  border: 2px solid #6a8fff; /* Highlight the border when focused */
}

.get-started:active {
  transform: translateY(2px); /* Slight push down effect when clicked */
  box-shadow: none; /* Remove shadow on click */
}


/* Animation */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

/* Larger Screens */
@media (min-width: 768px) {
  .header {
    border-radius: 10px;
    margin: 10px;

  }
  .menu-toggle {
    display: none;
  }

  .mobile-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background: transparent;
    box-shadow: none;
    position: static;
    padding: 0;
  }

  .menu-link {
    margin: 0 1rem;
    font-size: 1rem;
  }

  .get-started {
    margin-left: 1rem;
  }
}
