.success-payment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
  padding: 20px;
}

.success-payment-card {
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.success-payment-logo-container {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.success-payment-icon-container {
  margin-bottom: 20px;
}

.success-payment-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.success-payment-description {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.success-payment-info-box {
  background-color: #f0f8ff;
  padding: 15px;
  border-radius: 8px;
  text-align: left;
  margin-bottom: 20px;
}

.success-payment-info-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.success-payment-info-text {
  font-size: 14px;
  color: #333;
}

.success-payment-button-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* gap: 10px; */
}

.success-payment-btn {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s;
}

.success-payment-btn:hover {
  background-color: #0056b3;
}
