/* Mobile-first styling */
.signup-container {
  max-width: 400px;
  margin: 2rem auto;
  padding: 2rem;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
}

.signup-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #333;
}

.signup-form .form-group {
  margin-bottom: 1.5rem;
  text-align: left;
}

.signup-form .form-group label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #555;
}

.signup-form .form-group input {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}

.signup-form .form-group input:focus {
  border-color: #2575fc;
}

.btn-primary {
  background-color: #2575fc;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-home {
  background-color: #fff;
  color: #2575fc;
  padding: 0.75rem 1.5rem;
  border: 1px solid #2575fc;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 1.5rem;
}

.btn-home:hover {
  background-color: #f1f1f1;
}

.divider {
  margin: 1.5rem 0;
  font-size: 0.9rem;
  color: #aaa;
  position: relative;
}

.divider::before,
.divider::after {
  content: "";
  width: 40%;
  height: 1px;
  background: #ddd;
  position: absolute;
  top: 50%;
}

.divider::before {
  left: 0;
}

.divider::after {
  right: 0;
}

.google-signup-btn {
  margin: 1rem auto 0;
  width: 100%;
}

.signup-link {
  text-align: center;
  margin-top: 20px;
}

.signup-link-text {
  color: #007bff;
  text-decoration: none;
}

.signup-link-text:hover {
  text-decoration: underline;
}

/* Media Query for larger screens (desktop) */
@media (min-width: 768px) {
  .signup-container {
    max-width: 600px;
    padding: 1rem 3rem;
  }

  .signup-title {
    font-size: 2rem;
  }

  .signup-form .form-group {
    margin-bottom: 2rem;
  }

  .btn-primary {
    padding: 1rem 2rem;
    font-size: 1.1rem;
  }

  .divider {
    margin: 2rem 0;
  }
}
