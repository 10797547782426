/* General Form Container */
.contact-form-container {
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

/* Form Heading */
.contact-form-heading {
  text-align: center;
  color: #333;
  margin-bottom: 1.5rem;
  font-size: 24px;
}

/* Form Styles */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-form-group {
  display: flex;
  flex-direction: column;
}

.contact-form-label {
  font-size: 14px;
  color: #555;
  margin-bottom: 0.5rem;
}

.contact-form-input,
.contact-form-textarea {
  font-size: 16px;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.contact-form-input:focus,
.contact-form-textarea:focus {
  border-color: #007BFF;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.contact-form-textarea {
  min-height: 100px;
  resize: vertical;
}

/* Submit Button */
.contact-form-submit-button {
  padding: 0.75rem;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form-submit-button:hover {
  background-color: #0056b3;
}

/* Response Message */
.contact-form-response-message {
  margin-top: 1rem;
  text-align: center;
  font-size: 14px;
  color: #28a745;
}

.contact-form-error {
  color: red;
  font-size: 0.85rem;
  margin-top: 0.25rem;
}
